<template>
  <b-container fluid>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">Scheduled Call #{{ $route.params.id }} </h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <div class="d-flex flex-column flex-md-row justify-content-between">
                  <div class="d-flex flex-column justify-content-center">
                    <p class="h3 font-weight-bold text-muted">Customer</p>
                    <p class="mb-0 text-muted">Who is this call for?</p>
                  </div>
                  <div class="d-flex flex-column flex-lg-row justify-content-center">
                    <b-list-group>
                        <b-list-group-item href="#">
                            <p class="font-weight-bold mb-0  w-100-m-1">{{ scheduledCall.customer.forename }} {{ scheduledCall.customer.surname }}</p>
                            <p class="text-muted text-truncate mb-0 w-100-m-1"><small>{{ scheduledCall.customer.email }}</small></p>
                        </b-list-group-item>
                    </b-list-group>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <div class="d-flex flex-column flex-md-row justify-content-between">
                  <div class="d-flex flex-column justify-content-center">
                    <p class="h3 font-weight-bold text-muted">User</p>
                    <p class="mb-0 text-muted">Who is taking this call?</p>
                  </div>
                  <div class="d-flex flex-column flex-lg-row justify-content-center">
                    <b-list-group>
                        <b-list-group-item href="#" >
                            <p class="font-weight-bold mb-0  w-100-m-1">{{ scheduledCall.user.name }}</p>
                            <p class="text-muted text-truncate mb-0 w-100-m-1"><small>{{ scheduledCall.user.email }}</small></p>
                        </b-list-group-item>
                    </b-list-group>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="Name" :invalid-feedback="validationInvalidFeedback(errors, 'name')">
                <b-form-input :disabled="true" :state="validationState(errors, 'name')" v-model="scheduledCall.name" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="Contact Number" :invalid-feedback="validationInvalidFeedback(errors, 'contact_number')">
                <b-form-input :disabled="true" :state="validationState(errors, 'contact_number')" v-model="scheduledCall.contact_number" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Date" :invalid-feedback="validationInvalidFeedback(errors, 'scheduled_date')">
                <b-form-datepicker :disabled="true" v-model="scheduledCall.scheduled_date" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Time" :invalid-feedback="validationInvalidFeedback(errors, 'scheduled_time')">
                <b-form-timepicker :disabled="true" v-model="scheduledCall.scheduled_time" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="Duration (Minutes)" :invalid-feedback="validationInvalidFeedback(errors, 'call_duration')">
                <b-form-input :disabled="true" :state="validationState(errors, 'call_duration')" v-model="scheduledCall.call_duration" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="Note" :invalid-feedback="validationInvalidFeedback(errors, 'note')">
                <b-form-textarea :disabled="true" :state="validationState(errors, 'note')" v-model="scheduledCall.note" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col class="d-flex flex-row" cols="12" md="4" offset-md="8">
              <b-button block class="align-self-end mb-3" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Call Now</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import validation from '../../mixins/validation'
import { mapActions, mapGetters } from 'vuex'
import CustomerSearch from '../../components/CustomerSearch'
import UserSearch from '../../components/UserSearch'
import momentMixin from '../../mixins/momentMixin'

export default {
  mixins: [ validation, momentMixin ],
  components: { CustomerSearch, UserSearch },
  created () {
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id).then(response => {
          //
      })
    }
  },
  computed: {
    ...mapGetters('scheduled-calls', ['errors', 'isLoading', 'isSaving', 'single'])
  },
  data () {
    return {
      scheduledCall: {
        id: null,
        customer_id: null,
        customer: { id: null },
        user_id: null,
        user: { id: null },
        name: null,
        contact_number: null,
        scheduled_date: null,
        scheduled_time: null,
        call_duration: 30,
        note: null,
      }
    }
  },
  methods: {

    ...mapActions('scheduled-calls', ['fetch', 'save']),

    submit () {
      let data = {
        id: this.scheduledCall.id,
        customer_id: this.scheduledCall.customer_id,
        user_id: this.scheduledCall.user_id,
        name: this.scheduledCall.name,
        contact_number: this.scheduledCall.contact_number,
        scheduled_date: this.scheduledCall.scheduled_date,
        scheduled_time: this.scheduledCall.scheduled_time,
        call_duration: this.scheduledCall.call_duration,
        note: this.scheduledCall.note,
      }

    //   this.save(data)
    },

    customerUpdated() {
      this.scheduledCall.name = this.scheduledCall.customer.forename + ' ' + this.scheduledCall.customer.surname;
      this.scheduledCall.contact_number = this.scheduledCall.customer.mobile;
      this.scheduledCall.customer_id = this.scheduledCall.customer.id;
    },

    userUpdated() {
      this.scheduledCall.user_id = this.scheduledCall.user.id;
    },
  },
  watch: {
    single () {
      this.scheduledCall = this.single
    }
  }
}
</script>

<style>

</style>
