<template>
  <b-container fluid>
    <ResourceList
      can-delete create-to="dash.customers.create" resource="customer" soft-deletes title="My Customers"  :has-filters="true"
      :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination"
      :search-query="$route.query.query" @delete-many="onDeleteMany" @paginate="onPaginate"
      @search="onSearch">

      <template #filters>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Customer Status">
              <b-form-select v-model="customerStatus" @change="onStatusChange">
                <b-form-select-option :value="null">All</b-form-select-option>
                <b-form-select-option v-for="status in statuses" :key="status" :value="status">{{ status }}</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </template>

      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-1 text-primary"><router-link class="text-dark" :to="{name: 'dash.customers.view', params: { id: item.id }}">{{ [item.forename, item.surname].join(' ') }}</router-link></p>
            <p class="align-self-end mb-1 text-muted"><FriendlyDate :date="item.created_at" /></p>

          </div>
          <div class="d-flex flex-row flex-fill mb-0 mb-md-1">
            <div class="d-flex flex-column flex-fill">
              <p class="mb-0">{{ item.email }}</p>
              <small v-if="item.tickets_count" class="mb-0 text-danger"><fa-icon class="mr-1" icon="flag"/>This customer has a ticket.</small>
            </div>
            <p class="mb-0 text-muted" v-if="item.status">{{ item.status }}</p>
          </div>
          <div class="d-none d-md-flex flex-row">
            <b-button class="mr-1 mb-0" size="sm" variant="primary" v-b-popover.hover.bottom="'View more information about this customer.'" :to="{name: 'dash.customers.view', params: { id: item.id }}"><b-icon icon="person" /> View</b-button>
            
            <b-dropdown size="sm" :id="`call-drop-down-${ item.id }`" v-if="item.mobile && item.landline">
              <template #button-content>
                <b-icon icon="telephone-outbound" /> Call
              </template>
              <b-dropdown-item @click="initiateCall(item.mobile)">Mobile</b-dropdown-item>
              <b-dropdown-item @click="initiateCall(item.landline)">Landline</b-dropdown-item>
            </b-dropdown>

            <b-button class="mr-1 mb-0" size="sm" variant="secondary" v-if="!item.landline && item.mobile" @click="initiateCall(item.mobile)" v-b-popover.hover.bottom="'Uses your desktop phone to initiate a call to the customer.'"><b-icon icon="telephone-outbound" /> Call Mobile</b-button>
            <b-button class="mr-1 mb-0" size="sm" variant="secondary" v-if="!item.mobile && item.landline" @click="initiateCall(item.landline)" v-b-popover.hover.bottom="'Uses your desktop phone to initiate a call to the customer.'"><b-icon icon="telephone-outbound" /> Call</b-button>

            <p class="align-self-center ml-2 mb-0" v-if="item.last_contacted_at">Last contacted <FriendlyDate class="align-self-center d-inline text-muted" :date="item.last_contacted_at" />.</p>
          </div>
        </div>
      </template>

    </ResourceList>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser],
  created () {
    this.getStatuses().then(response => {
      this.statuses = response.data
    })
    this.fetchManyMine(this.$route.query)
  },
  components: {
    FriendlyDate,
    ResourceList
  },
  data: () => ({
    customerStatus: null,
    statuses: [],
  }),
  computed: {
    ...mapGetters('customers', ['data', 'isDeleting', 'isLoading', 'pagination'])
  },
  mounted() {
    this.$root.$on('call-connected', call => {
      if(call.customer_id) this.updateLastContactedAt(call.customer_id)
    })
  },
  methods: {
    ...mapActions('customers', ['deleteMany', 'fetchManyMine', 'updateLastContactedAt', 'getStatuses']),
    async onDeleteMany ({ ids, modalId }) {
      await this.deleteMany({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchManyMine(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchManyMine({ ...this.$route.query, page, customerStatus: this.customerStatus })
      }
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchManyMine({ ...this.$route.query, query, customerStatus: this.customerStatus })
      }
    },
    async onStatusChange (status) {
      if (this.$route.query.status !== status) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, status } })
        await this.fetchManyMine({ ...this.$route.query, customerStatus: this.customerStatus })
      }
    },
    initiateCall(number) { this.$root.$emit('initiate-call', number); }
  }
}
</script>

<style>

</style>
