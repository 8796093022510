export const TEMPLATES_BATCH_DELETE_REQUEST = 'TEMPLATES_BATCH_DELETE_REQUEST'
export const TEMPLATES_BATCH_DELETE_SUCCESS = 'TEMPLATES_BATCH_DELETE_SUCCESS'
export const TEMPLATES_BATCH_DELETE_FAILURE = 'TEMPLATES_BATCH_DELETE_FAILURE'

export const TEMPLATES_FETCH_MANY_REQUEST = 'TEMPLATES_FETCH_MANY_REQUEST'
export const TEMPLATES_FETCH_MANY_SUCCESS = 'TEMPLATES_FETCH_MANY_SUCCESS'
export const TEMPLATES_FETCH_MANY_FAILURE = 'TEMPLATES_FETCH_MANY_FAILURE'

export const TEMPLATES_FETCH_REQUEST = 'TEMPLATES_FETCH_REQUEST'
export const TEMPLATES_FETCH_SUCCESS = 'TEMPLATES_FETCH_SUCCESS'
export const TEMPLATES_FETCH_FAILURE = 'TEMPLATES_FETCH_FAILURE'

export const TEMPLATES_SAVE_REQUEST = 'TEMPLATES_SAVE_REQUEST'
export const TEMPLATES_SAVE_SUCCESS = 'TEMPLATES_SAVE_SUCCESS'
export const TEMPLATES_SAVE_FAILURE = 'TEMPLATES_SAVE_FAILURE'

export const EMPTY_TEMPLATES = 'EMPTY_TEMPLATES'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
