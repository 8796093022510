<template>
  <b-container fluid>
    <ResourceList
      :can-create="false" resource="missed call" soft-deletes title="Missed Calls"
      :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination" :can-select-many="false" :has-buttons="false"
      :search-query="$route.query.query" @paginate="onPaginate"
      @search="onSearch">

      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill p-3">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-1" v-if="item.customer"><router-link class="h5 text-primary" v-b-popover.hover.bottom="'View the customer we matched this number to.'" :to="{name: 'dash.customers.view', params: {id: item.customer_id }}">{{ [item.customer.forename, item.customer.surname].join(' ') }}</router-link><small class="ml-1 text-muted">({{ item.from }})</small></p>
            <p class="h5 flex-fill mb-1 text-primary" v-else>{{ item.from }}</p>
            <p class="align-self-end mb-1 text-muted"><FriendlyDate :date="item.date_time" /></p>
          </div>

          <div class="d-none d-md-flex flex-row mb-1">
            <span class="text-muted">Customer Called {{ item.to }}</span>
          </div>

          <div class="d-none d-md-flex flex-row mt-1">
            <b-button class="mr-1 mb-0" size="sm" variant="secondary" @click="callBack(item.from)" v-b-popover.hover.bottom="'Uses your desktop phone to initiate a call to the customer.'"><b-icon icon="telephone-outbound" /> Call Back</b-button>
            <b-button v-if="item.customer" class="mr-1 mb-0" size="sm" variant="primary" @click="doSendCallAttempted(item)" v-b-popover.hover.bottom="'Marks the missed call as unreachable.'"><b-icon icon="telephone-x" /> Unreachable</b-button>
            <b-button class="mr-1 mb-0" @click="dismissCall(item)" size="sm" variant="primary" v-b-popover.hover.bottom="'Dismiss all calls from this number.'"><b-icon icon="telephone-minus" /> Dismiss All</b-button>
          </div>
        </div>
      </template>

    </ResourceList>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser],
  created () {
    this.fetchManyMissed(this.$route.query)
  },
  components: {
    FriendlyDate,
    ResourceList
  },
  computed: {
    ...mapGetters('calls', ['data', 'isDeleting', 'isLoading', 'pagination'])
  },
  methods: {
    ...mapActions('calls', ['batchDelete', 'fetchManyMissed', 'sendCallAttempted', 'doDismissCall']),
    async onDeleteMany ({ ids, modalId }) {
      await this.batchDelete({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchManyMissed(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchManyMissed({ ...this.$route.query, page })
      }
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchManyMissed({ ...this.$route.query, query })
      }
    },
    callBack(number) { this.$root.$emit('initiate-call', number) },
    doSendCallAttempted(call) { this.sendCallAttempted(call.id) },
    dismissCall(call) { this.doDismissCall(call.id) },
  }
}
</script>

<style>

</style>
