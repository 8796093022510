import {
  SCHEDULED_CALLS_BATCH_DELETE_REQUEST,
  SCHEDULED_CALLS_BATCH_DELETE_SUCCESS,
  SCHEDULED_CALLS_BATCH_DELETE_FAILURE,
  SCHEDULED_CALLS_FETCH_MANY_REQUEST,
  SCHEDULED_CALLS_FETCH_MANY_SUCCESS,
  SCHEDULED_CALLS_FETCH_MANY_FAILURE,
  SCHEDULED_CALLS_FETCH_REQUEST,
  SCHEDULED_CALLS_FETCH_SUCCESS,
  SCHEDULED_CALLS_FETCH_FAILURE,
  SCHEDULED_CALLS_SAVE_REQUEST,
  SCHEDULED_CALLS_SAVE_SUCCESS,
  SCHEDULED_CALLS_SAVE_FAILURE,

  EMPTY_SCHEDULED_CALLS,
  CLEAR_ERRORS
} from './types'

export default {
  deleteMany ({ commit }, { ids = [] }) {
    commit(SCHEDULED_CALLS_BATCH_DELETE_REQUEST)
    return window.axios.post('/scheduled-calls', { _method: 'DELETE', ids }).then(response => {
      commit(SCHEDULED_CALLS_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SCHEDULED_CALLS_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchMany ({ commit }, { query = '', page = 1 }) {
    commit(SCHEDULED_CALLS_FETCH_MANY_REQUEST)
    return window.axios.get('/scheduled-calls', { params: { query, page } }).then(response => {
      commit(SCHEDULED_CALLS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SCHEDULED_CALLS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetch ({ commit }, id) {
    commit(SCHEDULED_CALLS_FETCH_REQUEST)
    return window.axios.get(`/scheduled-calls/${id}`).then(response => {
      commit(SCHEDULED_CALLS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SCHEDULED_CALLS_FETCH_FAILURE, error)
      throw error
    })
  },
  save ({ commit }, scheduledCall) {
    const path = scheduledCall.id ? `/scheduled-calls/${scheduledCall.id}` : '/scheduled-calls'
    const method = scheduledCall.id ? 'put' : 'post'

    commit(SCHEDULED_CALLS_SAVE_REQUEST)
    return window.axios[method](path, scheduledCall).then(response => {
      commit(SCHEDULED_CALLS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(SCHEDULED_CALLS_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(SCHEDULED_CALLS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  empty({ commit }) {
    commit(EMPTY_SCHEDULED_CALLS);
  },
  clearErrors({ commit }) {
    commit(CLEAR_ERRORS)
  }
}
