<template>
  <resource-search @delete="() => this.$emit('delete')"  @input="id => this.$emit('input', id)" module="users" :show-delete="showDelete" :error-feed="errorFeed" :error-key="errorKey" :wait-for-input="waitForInput" :key="value.id" :fetch-params="extraFetchParams" v-model="value">
    <template #popover-list="{ result }">
      <p class="font-weight-bold mb-0  w-100-m-1">{{ result.name }}</p>
      <p class="text-muted text-truncate mb-0 w-100-m-1"><small>{{ result.email }}</small></p>
    </template>

    <template #selected="{ selected }">
      <p class="font-weight-bold mb-0  w-100-m-1">{{ selected.name }}</p>
      <p class="text-muted text-truncate mb-0 w-100-m-1"><small>{{ selected.email }}</small></p>
    </template>
  </resource-search>
</template>

<script>
import ResourceSearch from './ResourceSearch.vue'
import momentMixin from '../mixins/momentMixin'

export default {
  mixins: [momentMixin],
  components: { ResourceSearch },
  props: {
    showDelete: {
      type: Boolean,
      default: false,
    },
    waitForInput: {
      type: Boolean,
      default: true,
    },
    errorFeed: Object,
    errorKey: String,
    type: String,
    value: Object,
    timesheetSearch: {
      type: Boolean,
      default: false
    },
    timesheetStartDate: [String, Date],
    timesheetEndDate: [String, Date],
  },
  computed: {
    extraFetchParams() {
      var returnParams = {};

      if(this.timesheetSearch) {
        returnParams = { ...returnParams, timesheet_search: true };

        if(this.timesheetStartDate && this.timesheetEndDate) {
          returnParams = { ...returnParams, timesheet_start_date: this.moment(this.timesheetStartDate).format('DD-MM-YYYY'), timesheet_end_date: this.moment(this.timesheetEndDate).format('DD-MM-YYYY') };
        }
      }

      if(this.type) {
        returnParams = { ...returnParams, type: this.type };
      }

      return returnParams;
    }
  },
}
</script>

<style>

</style>