<template>
  <b-container fluid>
    <ResourceList
      can-delete create-to="dash.calls.scheduled.create" resource="scheduled call" title="Scheduled Calls"
      :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination"
      :search-query="$route.query.query" @delete-many="onDeleteMany" @paginate="onPaginate"
      :hide-list="calendarDisplay"
      :can-search="false">

      <template #buttons>
        <b-dd-item-button @click="calendarDisplay = !calendarDisplay">{{ calendarDisplay ? 'View List' : 'View Calendar' }}</b-dd-item-button>
      </template>

      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-1 text-primary"><router-link class="text-dark" :to="{name: 'dash.calls.scheduled.view', params: { id: item.id }}">{{ [item.customer.forename, item.customer.surname].join(' ') }}</router-link></p>
            <p class="align-self-end mb-0 text-muted">Call Scheduled <FriendlyDate :date="item.scheduled_date + ' ' + item.scheduled_time" /></p>
          </div>
          <div class="d-flex flex-row flex-fill mb-0 mb-md-1">
            <p class="mb-0 flex-fill">{{ item.note }}</p>
          </div>
          <div class="d-none d-md-flex flex-row">
            <b-button class="mr-1 mb-0" size="sm" variant="primary" v-b-popover.hover.bottom="'View customer details.'" :to="{ name: 'dash.customers.view', params: { id: item.customer.id } }"><b-icon icon="person" /> View</b-button>
            <b-button class="mr-1 mb-0" size="sm" variant="secondary" v-b-popover.hover.bottom="`Call ${item.contact_number}`"><b-icon icon="telephone-outbound" /> Call</b-button>
            <b-button class="mr-1 mb-0" size="sm" variant="secondary" v-b-popover.hover.bottom="'Send a text to the customer and dismiss.'"><b-icon icon="telephone-outbound" /> Unreachable</b-button>
          </div>
        </div>
      </template>

    </ResourceList>

    <FullCalendar :options="calendarOptions" v-if="calendarDisplay"/>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'

// Calendar.
import FullCalendar from '@fullcalendar/vue'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

export default {
  mixins: [currentUser,momentMixin],
  data () {
    return {
      calendarDisplay: false,

      calendarOptions: {
        locale: 'gb',
        plugins: [
          timeGridPlugin,
          interactionPlugin
        ],
        eventClick: (info) => {
          this.$router.push({
            name: 'dash.calls.scheduled.view',
            query: {
              ...this.$route.query,
            },
            params: {
              id: info.event.id
            }
          })
        },
        firstDay: 1,
        allDaySlot: false,
        initialView: 'timeGridWeek',
        events: [],
      }
    }
  },
  created () {
    this.fetchMany(this.$route.query)
  },
  components: {
    FriendlyDate,
    ResourceList,
    FullCalendar
  },
  computed: {
    ...mapGetters('scheduled-calls', ['data', 'isDeleting', 'isLoading', 'pagination']),
    calendarEvents() {
      var self = this;
      return this.data.map(function(event) {
        return {
          'id': event.id,
          'title': 'Call with ' + event.name,
          'start': self.moment(event.scheduled_date + ' ' + event.scheduled_time).toDate(),
          'end': self.moment(event.scheduled_date + ' ' + event.scheduled_time).add(event.call_duration, 'minutes').toDate(),
        };
      });
    },
  },
  methods: {
    ...mapActions('scheduled-calls', ['deleteMany', 'fetchMany']),
    async onDeleteMany ({ ids, modalId }) {
      await this.deleteMany({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchMany(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchMany({ ...this.$route.query, page })
      }
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchMany({ ...this.$route.query, query })
      }
    }
  },
  watch: {
    data(data) {
      this.calendarOptions.events = this.calendarEvents;
    }
  }
}
</script>

<style>

</style>
