<template>
  <div class="search">
    <div>
      <b-form-group :invalid-feedback="validationInvalidFeedback(errorFeed, errorKey)" class="mb-0">
        <b-form-input :state="validationState(errorFeed, errorKey)" class="mb-0" :disabled="disabled" :placeholder="`Search for postcodes...`" tabindex="0" :id="`search-field-${this._uid}`" @update="search" v-model="value"></b-form-input>
      </b-form-group>
      <b-popover class="p-0 w-100" placement="bottom" :target="`search-field-${this._uid}`" triggers="focus" id="search-results">
        <div class="text-center">
          <p class="my-3 text-muted" v-if="!loading && !results.length">Please start typing to search for postcodes.</p>
          <b-spinner class="mx-4 my-2" v-if="loading || !results"></b-spinner>
        </div>
        <b-list-group v-if="!loading && results">
          <b-list-group-item @click.prevent="select(result)" href="#" :key="result[0]" v-for="result in results">
            <div class="d-flex flex-column">
              <p class="mb-0" v-if="result[0]">{{ result[0] }}</p>
              <small class="text-muted" v-if="result[3]">{{ result[3] }}</small>
              <small class="text-muted" v-if="result[4]">{{ result[4] }}</small>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-popover>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import validation from '../mixins/validation'

export default {
  mixins: [validation],
  props: {
    disabled: Boolean,
    module: String,
    errorFeed: Object,
    errorKey: String,
    inputState: {
      type: Boolean,
      default: null,
    },
    value: String,
  },
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    ...mapState({
      results(state) {
        return state.postcodes.data ?? [];
      },
      loading(state) {
        return state.postcodes.loading;
      }
    }),
  },
  methods: {
    ...mapActions({
      fetch(dispatch, payload) {
        return dispatch('postcodes/fetchMany', payload);
      },
      fetchSingle(dispatch, payload) {
        return dispatch('postcodes/fetch', payload);
      },
      empty(dispatch, payload) {
        return dispatch('postcodes/empty');
      },
    }),
    select(address) {
      this.$emit('address', {
          address_line_1: address[0],
          address_line_2: address[1],
          city: address[3],
          county: address[4],
          postcode: this.value,
      });
      this.empty();
    },
    search() {
      this.$emit('input', this.value)
      return this.fetch({ query: this.value })
    },
  },
}
</script>

<style lang="scss">
#search-results {
  max-width: 50%;
  width: 50%;
  max-height: 50vh;
  overflow-y: scroll;

  .popover-body {
    padding: 0 !important;
    border: none;
  }
  .list-group-item {
    border-left: none;
    border-right: none;
  }
  .list-group-item:first-child {
    border-top: none;
  }
  .list-group-item:last-child {
    border-bottom: none;
  }
}


</style>
