<template>
  <b-container fluid>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? 'Update' : 'Create' }} Customer</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Forename" :invalid-feedback="validationInvalidFeedback(errors.errors, 'forename')">
                <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'forename')" v-model="customer.forename" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Surname" :invalid-feedback="validationInvalidFeedback(errors.errors, 'surname')">
                <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'surname')" v-model="customer.surname" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Mobile" :invalid-feedback="validationInvalidFeedback(errors.errors, 'mobile')">
                <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'mobile')" v-model="customer.mobile" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Second Mobile" :invalid-feedback="validationInvalidFeedback(errors.errors, 'second_mobile')">
                <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'second_mobile')" v-model="customer.second_mobile" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Landline" :invalid-feedback="validationInvalidFeedback(errors.errors, 'landline')">
                <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'landline')" v-model="customer.landline" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Email Address" :invalid-feedback="validationInvalidFeedback(errors.errors, 'email')">
                <b-form-input :disabled="isSaving" type="email" :state="validationState(errors.errors, 'email')" v-model="customer.email" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Status" :invalid-feedback="validationInvalidFeedback(errors.errors, 'status')">
                <b-form-select :disabled="isSaving" :state="validationState(errors.errors, 'status')" v-model="customer.status">
                  <b-form-select-option :value="null">Please select a status</b-form-select-option>
                  <b-form-select-option v-for="(status, statusInd) in statuses" :key="`status${statusInd}`" :value="status">{{ status }}</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="!$route.params.id">
            <b-col cols="12">
              <b-form-group label="Source" :invalid-feedback="validationInvalidFeedback(errors.errors, 'source')">
                <b-form-select :disabled="isSaving" :state="validationState(errors.errors, 'source')" v-model="customer.source">
                  <b-form-select-option :value="null">Please select a source</b-form-select-option>
                  <b-form-select-option value="Google">Google</b-form-select-option>
                  <b-form-select-option value="Facebook">Facebook</b-form-select-option>
                  <b-form-select-option value="Bing">Bing</b-form-select-option>
                  <b-form-select-option value="Walk In">Walk In</b-form-select-option>
                  <b-form-select-option value="Referral">Referral</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <div class="d-flex flex-column flex-md-row justify-content-between">
                  <div class="d-flex flex-column justify-content-center">
                    <p class="h3 font-weight-bold text-muted">User</p>
                    <p class="mb-0 text-muted">Who deals with this customer?</p>
                  </div>
                  <div class="d-flex flex-column flex-lg-row justify-content-center mt-3 mt-md-0">
                    <user-search :error-feed="errors.errors" :error-key="'user_id'" class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100" type="user" @input="userUpdated" v-model="customer.user" />
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <!-- Customer Notes -->
          <b-row class="mb-4">
              <b-col cols="12">
                  <b-card>
                      <b-row>
                          <b-col class="d-flex flex-row" cols="12" md="6" @click.prevent="toggleCollapse('customer_notes')" role="button">
                              <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['customer_notes'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                              <div class="d-flex flex-column flex-fill ml-4">
                                  <p class="h4 font-weight-bold mb-0">Customer Notes</p>
                              </div>
                          </b-col>
                      </b-row>
                      <b-row class="mt-4" v-if="!collapsed['customer_notes']">
                          <!-- Add New Note Field -->
                          <b-col cols="12">
                              <hr class="mt-0" />
                              <b-form-group label="Add a Note" :invalid-feedback="validationInvalidFeedback(errors.errors, 'newNote')">
                                  <b-form-textarea :state="validationState(errors.errors, 'newNote')" v-model="newNote" placeholder="Insert note here..." size="sm"></b-form-textarea>
                              </b-form-group>
                              <div class="d-flex flex-row flex-fill">
                                <b-button variant="primary" block @click="addNote">Add Note</b-button>
                              </div>
                          </b-col>
                          <b-col cols="12" v-if="customer.notes.length">
                              <hr />
                              <b-row v-for="(note, noteInd) in customer.notes" :key="noteInd">
                                  <b-col cols="12" :invalid-feedback="validationInvalidFeedback(errors.errors, 'note.' + noteInd + '.note')">
                                      <b-card no-body class="p-4 mt-3">
                                        <b-row>
                                          <b-col cols="11">
                                            <p class="white-space-pre mb-0">{{ note.note }}</p>
                                          </b-col>
                                          <b-col cols="1" class="d-flex flex-row align-items-start justify-content-end">
                                            <b-button variant="danger" @click="rmNote(noteInd)" size="sm"><fa-icon :icon="'trash'"/></b-button>
                                          </b-col>
                                        </b-row>
                                        <hr />
                                        <b-row>
                                            <b-col cols="9" class="d-flex flex-row justify-content-start align-items-center">
                                                <p class="note-created mb-0">Left by: {{ note.user.name }}</p>
                                            </b-col>
                                            <b-col cols="3" class="d-flex flex-row justify-content-end align-items-center">
                                                <p class="note-created text-muted mb-0"><FriendlyDate :date="note.created_at"></FriendlyDate></p>
                                            </b-col>
                                        </b-row>
                                      </b-card>
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-card>
              </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col cols="11" class="d-flex flex-column flex-fill justify-content-center">
                      <p class="h4 font-weight-bold mb-0">Billing Address</p>
                  </b-col>
                  <b-col cols="1" class="d-flex flex-row flex-fill align-items-start justify-content-center">
                    <fa-icon class="text-danger" icon="flag" v-if="computedAddressesAreDifferent"></fa-icon>
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <b-col cols="12">
                    <b-form-group label="Postcode Search" :invalid-feedback="validationInvalidFeedback(errors.errors, 'billing_address.postcode')">
                      <postcode-search :error-feed="errors.errors" error-key="billing_address.postcode" :disabled="isSaving" @address="address => { this.customer.billing_address = address }" v-model="customer.billing_address.postcode"></postcode-search>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Address Line 1" :invalid-feedback="validationInvalidFeedback(errors.errors, 'billing_address.address_line_1')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'billing_address.address_line_1')" v-model="customer.billing_address.address_line_1" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Address Line 2" :invalid-feedback="validationInvalidFeedback(errors.errors, 'billing_address.address_line_2')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'billing_address.address_line_2')" v-model="customer.billing_address.address_line_2" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="City" :invalid-feedback="validationInvalidFeedback(errors.errors, 'billing_address.city')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'billing_address.city')" v-model="customer.billing_address.city" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="County" :invalid-feedback="validationInvalidFeedback(errors.errors, 'billing_address.county')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'billing_address.county')" v-model="customer.billing_address.county" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>

                <b-row>
                  <b-col cols="11" class="d-flex flex-column flex-fill justify-content-center">
                      <p class="h4 font-weight-bold mb-0">Shipping Address</p>
                  </b-col>
                  <b-col cols="1" class="d-flex flex-row flex-fill align-items-start justify-content-center">
                    <fa-icon class="text-danger" icon="flag" v-if="computedAddressesAreDifferent"></fa-icon>
                  </b-col>
                </b-row>

                <b-row class="py-3">
                  <b-col cols="12">
                    <b-button size="sm" @click="setShippingAddressAsBilling" variant="primary">Copy Billing Address</b-button>
                  </b-col>
                </b-row>

                <b-row class="mt-2">
                  <b-col cols="12">
                    <b-form-group label="Postcode Search" :invalid-feedback="validationInvalidFeedback(errors.errors, 'shipping_address.postcode')">
                      <postcode-search :error-feed="errors.errors" error-key="shipping_address.postcode" :disabled="isSaving" @address="address => { this.customer.shipping_address = address }" v-model="customer.shipping_address.postcode"></postcode-search>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Address Line 1" :invalid-feedback="validationInvalidFeedback(errors.errors, 'shipping_address.address_line_1')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'shipping_address.address_line_1')" v-model="customer.shipping_address.address_line_1" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Address Line 2" :invalid-feedback="validationInvalidFeedback(errors.errors, 'shipping_address.address_line_2')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'shipping_address.address_line_2')" v-model="customer.shipping_address.address_line_2" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="City" :invalid-feedback="validationInvalidFeedback(errors.errors, 'shipping_address.city')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'shipping_address.city')" v-model="customer.shipping_address.city" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="County" :invalid-feedback="validationInvalidFeedback(errors.errors, 'shipping_address.county')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'shipping_address.county')" v-model="customer.shipping_address.county" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" @click.prevent="toggleCollapse('interested_in')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['interested_in'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Interested In</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['interested_in']">
                  <b-col cols="12" v-if="!customer.products.length" class="p-4">
                    <b-row>
                      <b-col class="text-center" cols="12">
                        <b-card class="py-3 text-center">
                          <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                          <p class="font-weight-normal h5 mb-0 text-dark">There are no products selected.</p>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" v-if="customer.products.length" class="p-4">
                    <product-search class="mb-3" @delete="rmProduct(ind)" show-delete type="product" v-model="customer.products[ind]" :key="product.id" v-for="(product, ind) in customer.products" />
                  </b-col>
                  <b-col cols="12">
                    <b-button block @click.prevent="addProduct" variant="primary"><fa-icon icon="plus" /> Add Product</b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" @click.prevent="toggleCollapse('additional_information')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['additional_information'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Additional Information</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['additional_information']">
                  <b-col cols="12">
                    <b-form-group label="Group Budget" :invalid-feedback="validationInvalidFeedback(errors.errors, 'group')">
                      <group-search :wait-for-input="false" :input-state="validationState(errors.errors, 'group')" class="mb-3" type="group" v-model="customer.group" @input="groupSelected"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col class="d-flex flex-row" cols="12" md="4" offset-md="8">
              <b-button block class="align-self-end mb-3" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import validation from '../../mixins/validation'
import { mapActions, mapGetters } from 'vuex'
import ProductSearch from '../../components/ProductSearch'
import UserSearch from '../../components/UserSearch'
import GroupSearch from '../../components/GroupSearch'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import FriendlyDate from '../../components/FriendlyDate'
import PostcodeSearch from '../../components/PostcodeSearch'

export default {
  components: { ProductSearch, GroupSearch, FriendlyDate, UserSearch, PostcodeSearch },
  mixins: [validation, currentUser, momentMixin],
  created () {
    this.getStatuses().then(response => {
      this.statuses = response.data
    })
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id).then(response => {
        if(response.data.products.length) {
          this.collapsed.interested_in = false
        }
      })
    }
  },
  mounted() {
    if(this.customer.user.id == null) {
      if(this.currentUser.act_as_user_id == null) {
        this.customer.user.id = this.currentUser.id
        this.customer.user_id = this.currentUser.id
      }else{
        this.customer.user.id = this.currentUser.act_as_user_id;
        this.customer.user_id = this.currentUser.act_as_user_id
      }
    }
  },
  computed: {
    ...mapGetters('customers', ['errors', 'isLoading', 'isSaving', 'single']),
    computedAddressesAreDifferent() {
      return !(
          (this.customer.shipping_address.address_line_1 == this.customer.billing_address.address_line_1) &&
          (this.customer.shipping_address.address_line_2 == this.customer.billing_address.address_line_2) &&
          (this.customer.shipping_address.city == this.customer.billing_address.city) &&
          (this.customer.shipping_address.county == this.customer.billing_address.county) &&
          (this.customer.shipping_address.postcode == this.customer.billing_address.postcode)
        )
    }
  },
  data () {
    return {
      collapsed: {
        billing_address: !this.$route.params.id,
        shipping_address: !this.$route.params.id,
        interested_in: true,
        additional_information: true,
        customer_notes: true,
      },
      correspondenceFilter: null,
      upcomingCorrespondenceFilter: null,
      correspondenceFilterOptions: [
        { text: '-- All Correspondence --', value: null },
        { text: 'Automated', value: 'automated' },
        { text: 'Calls', value: 'calls' },
        { text: 'Emails', value: 'emails' },
        { text: 'Manual', value: 'manual' },
        { text: 'Text Messages', value: 'sms' }
      ],

      statuses: [],
      
      newNote: null,

      customer: {
        products: [],
        billing_address: {
          address_line_1: '',
          address_line_2: '',
          city: '',
          county: '',
          postcode: '',
        },
        shipping_address: {
          address_line_1: '',
          address_line_2: '',
          city: '',
          county: '',
          postcode: '',
        },
        forename: null,
        surname: null,
        mobile: null,
        second_mobile: null,
        status: null,
        landline: null,
        email: null,
        group: null,
        email_confirmation: null,
        notes: [],
        user: { id: null },
        user_id: null,
        source: null,
      }
    }
  },
  methods: {

    ...mapActions('customers', ['fetch', 'save', 'getStatuses']),

    toggleCollapse (name) {
      this.collapsed[name] = !this.collapsed[name]
    },

    userUpdated() {
      this.customer.user_id = this.customer.user.id
    },

    submit () {
      let data = {
        id: this.$route.params.id,
        forename: this.customer.forename,
        surname: this.customer.surname,
        email: this.customer.email,
        email_confirmation: this.customer.email_confirmation,
        mobile: this.customer.mobile,
        second_mobile: this.customer.second_mobile,
        status: this.customer.status,
        landline: this.customer.landline,
        billing_address: {
          address_line_1: this.customer.billing_address.address_line_1,
          address_line_2: this.customer.billing_address.address_line_2,
          city: this.customer.billing_address.city,
          county: this.customer.billing_address.county,
          postcode: this.customer.billing_address.postcode,
        },
        shipping_address: {
          address_line_1: this.customer.shipping_address.address_line_1,
          address_line_2: this.customer.shipping_address.address_line_2,
          city: this.customer.shipping_address.city,
          county: this.customer.shipping_address.county,
          postcode: this.customer.shipping_address.postcode,
        },
        products: this.customer.products,
        group_id: this.customer.group_id,
        notes: this.customer.notes,
        user_id: this.customer.user_id,
      }

      if( !this.$route.params.id ) {
        data['source'] = this.customer.source
      }

      this.save(data).then(response => {
        if([ 200, 201 ].includes(response.status)) this.$router.push({
          name: 'dash.customers.view',
          params: {
            id: response.data.id,
            notifications: [{
              variant: 'success',
              title: `Customer #${response.data.id}`,
              subtitle: `${response.data.forename} ${response.data.surname}`,
              text: `You have ${!this.$route.params.id ? 'created a new' : 'updated an existing'} customer.`,
              timeout: 5000
            }]
          }
        })
      })
    },

    addNote() {
      if(this.newNote) {
        this.customer.notes = [{
            id: null,
            note: this.newNote,
            user: { name: this.currentUser.name },
            created_at: this.moment()
          }, ...this.customer.notes]
        this.newNote = null
      }
    },

    rmNote(ind) {
      this.customer.notes.splice(ind, 1)
    },

    addProduct() {
      this.customer.products.push({
        id: null,
      })
    },

    rmProduct(ind) {
      this.customer.products.splice(ind, 1);
    },

    setShippingAddressAsBilling() {
      this.customer.shipping_address = {...this.customer.billing_address};
    },

    groupSelected() {
      this.customer.group_id = this.customer.group.id;
    }
  },
  watch: {
    single () {
      if(this.single.user == null) {
        this.single.user = { id: this.currentUser.id }
        this.single.user_id = this.currentUser.id
      }
      if(!this.single.shipping_address) {
        this.single.shipping_address = {
          address_line_1: '',
          address_line_2: '',
          city: '',
          county: '',
          postcode: ''
        };
      }
      if(!this.single.billing_address) {
        this.single.billing_address = {
          address_line_1: '',
          address_line_2: '',
          city: '',
          county: '',
          postcode: ''
        };
      }
      this.customer = this.single
      this.customer.email_confirmation = this.single.email;

      if(this.customer.notes && this.customer.notes.length) {
        this.collapsed.customer_notes = false
      }
    }
  }
}
</script>

<style>
  
</style>
