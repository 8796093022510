import {
  POSTCODES_FETCH_MANY_REQUEST,
  POSTCODES_FETCH_MANY_SUCCESS,
  POSTCODES_FETCH_MANY_FAILURE,

  EMPTY_POSTCODES,
  CLEAR_ERRORS
} from './types'

export default {
  fetchMany ({ commit }, { query = '' }) {
    commit(POSTCODES_FETCH_MANY_REQUEST)
    return window.axios.get('/postcodes/get', { params: { query } }).then(response => {
      commit(POSTCODES_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(POSTCODES_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  empty({ commit }) {
    commit(EMPTY_POSTCODES);
  },
  clearErrors({ commit }) {
    commit(CLEAR_ERRORS)
  }
}
