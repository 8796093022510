<template>
  <resource-search @delete="() => this.$emit('delete')" :isSlim="slim" @input="id => this.$emit('input', id)" module="products" :show-delete="showDelete" :error-feed="errorFeed" :error-key="errorKey" :wait-for-input="waitForInput" :fetch-params="extraFetchParams" v-model="value">
    <template #popover-list="{ result }">
        <p class="font-weight-bold mb-0  w-100-m-1">{{ result.name }}</p>
        <p class="text-muted text-truncate mb-0 w-100-m-1" v-if="!slim"><small>£{{ result.price }}</small></p>
    </template>

    <template #selected="{ selected }">
      <div class="d-flex flex-column justify-content-center h-100">
        <p class="font-weight-bold mb-0" :class="{ 'text-truncate ml-3': slim }">{{ selected.name }}</p>
        <p class="text-muted mb-0" v-if="!slim">£{{ useDisplayPrice ? displayPrice : selected.price }}</p>
      </div>
    </template>

    <template #selected-inline-form-area>
      <slot name="selected-inline-form-area" />
    </template>

    <template #selected-form-area>
      <slot name="form-area" />
    </template>
  </resource-search>
</template>

<script>
import ResourceSearch from './ResourceSearch.vue'

export default {
  components: { ResourceSearch },
  props: {
    useDisplayPrice: {
      type: Boolean,
      default: false,
    },
    slim: {
      type: Boolean,
      default: false,
    },
    displayPrice: {
      type: [Number, String],
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    waitForInput: {
      type: Boolean,
      default: true,
    },
    errorFeed: Object,
    errorKey: String,
    type: String,
    value: Object,
  },
  computed: {
    extraFetchParams() {
      if (this.type) {
        return { type: this.type };
      }

      return {};
    }
  }
}
</script>
