export const SCHEDULED_CALLS_BATCH_DELETE_REQUEST = 'SCHEDULED_CALLS_BATCH_DELETE_REQUEST'
export const SCHEDULED_CALLS_BATCH_DELETE_SUCCESS = 'SCHEDULED_CALLS_BATCH_DELETE_SUCCESS'
export const SCHEDULED_CALLS_BATCH_DELETE_FAILURE = 'SCHEDULED_CALLS_BATCH_DELETE_FAILURE'

export const SCHEDULED_CALLS_FETCH_MANY_REQUEST = 'SCHEDULED_CALLS_FETCH_MANY_REQUEST'
export const SCHEDULED_CALLS_FETCH_MANY_SUCCESS = 'SCHEDULED_CALLS_FETCH_MANY_SUCCESS'
export const SCHEDULED_CALLS_FETCH_MANY_FAILURE = 'SCHEDULED_CALLS_FETCH_MANY_FAILURE'

export const SCHEDULED_CALLS_FETCH_REQUEST = 'SCHEDULED_CALLS_FETCH_REQUEST'
export const SCHEDULED_CALLS_FETCH_SUCCESS = 'SCHEDULED_CALLS_FETCH_SUCCESS'
export const SCHEDULED_CALLS_FETCH_FAILURE = 'SCHEDULED_CALLS_FETCH_FAILURE'

export const SCHEDULED_CALLS_SAVE_REQUEST = 'SCHEDULED_CALLS_SAVE_REQUEST'
export const SCHEDULED_CALLS_SAVE_SUCCESS = 'SCHEDULED_CALLS_SAVE_SUCCESS'
export const SCHEDULED_CALLS_SAVE_FAILURE = 'SCHEDULED_CALLS_SAVE_FAILURE'

export const EMPTY_SCHEDULED_CALLS = 'EMPTY_SCHEDULED_CALLS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
