import {
  POSTCODES_FETCH_MANY_REQUEST, POSTCODES_FETCH_MANY_SUCCESS, POSTCODES_FETCH_MANY_FAILURE,
  
  EMPTY_POSTCODES,
  CLEAR_ERRORS
} from './types'

export default {
  [POSTCODES_FETCH_MANY_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [POSTCODES_FETCH_MANY_SUCCESS] (state, { data }) {
    state.data = data
    state.loading = false
  },
  [POSTCODES_FETCH_MANY_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [EMPTY_POSTCODES] (state) {
    state.data = []
    state.loading = false
    state.saving = false
  },
  [CLEAR_ERRORS] (state) {
    state.validationErrors = []
    state.loading = false
    state.saving = false
  },
}
