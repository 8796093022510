<template>
  <resource-search @delete="() => this.$emit('delete')" @input="id => this.$emit('input', id)" module="groups" :show-delete="showDelete" :error-feed="errorFeed" :error-key="errorKey" :wait-for-input="waitForInput" :fetch-params="extraFetchParams" v-model="value">
    <template #popover-list="{ result }">
      <p class="font-weight-bold mb-0  w-100-m-1">{{ result.name }}</p>
      <p class="text-muted text-truncate mb-0 w-100-m-1"><small>£{{ result.budget }}</small></p>
    </template>

    <template #selected="{ selected }">
      <p class="font-weight-bold mb-0  w-100-m-1">{{ selected.name }}</p>
      <p class="text-muted text-truncate mb-0 w-100-m-1"><small>£{{ selected.budget }}</small></p>
    </template>
  </resource-search>
</template>

<script>
import ResourceSearch from './ResourceSearch.vue'

export default {
  components: { ResourceSearch },
  props: {
    showDelete: {
      type: Boolean,
      default: false,
    },
    errorFeed: Object,
    errorKey: String,
    waitForInput: {
      type: Boolean,
      default: true,
    },
    type: String,
    value: Object,
  },
  computed: {
    extraFetchParams() {
      if (this.type) {
        return { type: this.type };
      }

      return {};
    }
  }
}
</script>

<style>

</style>