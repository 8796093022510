export const GROUPS_BATCH_DELETE_REQUEST = 'GROUPS_BATCH_DELETE_REQUEST'
export const GROUPS_BATCH_DELETE_SUCCESS = 'GROUPS_BATCH_DELETE_SUCCESS'
export const GROUPS_BATCH_DELETE_FAILURE = 'GROUPS_BATCH_DELETE_FAILURE'

export const GROUPS_FETCH_MANY_REQUEST = 'GROUPS_FETCH_MANY_REQUEST'
export const GROUPS_FETCH_MANY_SUCCESS = 'GROUPS_FETCH_MANY_SUCCESS'
export const GROUPS_FETCH_MANY_FAILURE = 'GROUPS_FETCH_MANY_FAILURE'

export const GROUPS_FETCH_REQUEST = 'GROUPS_FETCH_REQUEST'
export const GROUPS_FETCH_SUCCESS = 'GROUPS_FETCH_SUCCESS'
export const GROUPS_FETCH_FAILURE = 'GROUPS_FETCH_FAILURE'

export const GROUPS_SAVE_REQUEST = 'GROUPS_SAVE_REQUEST'
export const GROUPS_SAVE_SUCCESS = 'GROUPS_SAVE_SUCCESS'
export const GROUPS_SAVE_FAILURE = 'GROUPS_SAVE_FAILURE'

export const EMPTY_GROUPS = 'EMPTY_GROUPS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
