<template>
  <b-container fluid>
    <ResourceList
      resource="call" soft-deletes title="Calls"
      :can-create="false" :can-select-many="false" :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination" :has-buttons="false"
      :search-query="$route.query.query" @delete-many="onDeleteMany" @paginate="onPaginate"
      @search="onSearch">

      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill p-3">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-1" v-if="item.customer"><router-link class="h5 text-primary" v-b-popover.hover.bottom="'View the customer we matched this number to.'" :to="{name: 'dash.customers.view', params: {id: item.customer_id }}"> {{ item.customer_identifier.identifier }}<small class="text-muted" v-if="item.customer_identifier.identifier != item.customer_identifier.contact_number"> ({{ item.customer_identifier.contact_number }})</small> <small v-if="item.to == 'local'">(Local)</small></router-link></p>
            <p class="h5 flex-fill mb-1 text-primary" v-else>{{ item.customer_identifier.contact_number }}</p>
            <p class="align-self-start mb-1 text-muted"> <FriendlyDate :date="item.date_time" /></p>
          </div>
          <div class="d-none d-md-flex flex-row mb-1" v-if="item.type == 'inbound'">
            <span class="text-muted">Customer Called {{ item.to }}</span>
          </div>
          <div class="d-none d-md-flex flex-row">
            <small class="text-muted mr-1">{{ item.type | capitalise }}{{ durationIs(item) }} | </small>
            <small class="text-muted" v-if="item.handled_by_identifier && !item.handled_by_identifier.missed">Handled By: {{ item.handled_by_identifier.identifier }}</small>
            <small class="text-muted" v-if="item.handled_by_identifier && item.handled_by_identifier.missed">{{ item.handled_by_identifier.missed }}</small>
          </div>
          <div class="d-none d-md-flex flex-row mt-1">
            <b-button v-b-popover.hover.top="`Call ${item.customer_identifier.contact_number}`"><b-icon class="mr-1" icon="telephone-outbound" />Call</b-button>
          </div>
        </div>
      </template>

    </ResourceList>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser,momentMixin],
  created () {
    this.fetchMany(this.$route.query)
  },
  components: {
    FriendlyDate,
    ResourceList,
  },
  computed: {
    ...mapGetters('calls', ['data', 'isDeleting', 'isLoading', 'pagination']),
  },
  methods: {
    ...mapActions('calls', ['batchDelete', 'fetchMany']),
    async onDeleteMany ({ ids, modalId }) {
      await this.batchDelete({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchMany(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchMany({ ...this.$route.query, page })
      }
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchMany({ ...this.$route.query, query })
      }
    },
    durationIs(item) {
      if(item.duration > 60) {
        var minutes = Math.round(item.duration/60);

        return ` for ${minutes} minutes`
      }
      return ''
    }
  },
  filters: {
      capitalise(v) {
          if(!v) return 'Error.'
          return String(v).charAt(0).toUpperCase() + String(v).slice(1)
      }
  }
}
</script>

<style>

</style>
