import {
  PRODUCTS_BATCH_DELETE_REQUEST,
  PRODUCTS_BATCH_DELETE_SUCCESS,
  PRODUCTS_BATCH_DELETE_FAILURE,
  PRODUCTS_FETCH_MANY_REQUEST,
  PRODUCTS_FETCH_MANY_SUCCESS,
  PRODUCTS_FETCH_MANY_FAILURE,
  PRODUCTS_FETCH_REQUEST,
  PRODUCTS_FETCH_SUCCESS,
  PRODUCTS_FETCH_FAILURE,
  PRODUCTS_SAVE_REQUEST,
  PRODUCTS_SAVE_SUCCESS,
  PRODUCTS_SAVE_FAILURE,

  EMPTY_PRODUCTS,
  CLEAR_ERRORS
} from './types'

export default {
  deleteMany ({ commit }, { ids = [] }) {
    commit(PRODUCTS_BATCH_DELETE_REQUEST)
    return window.axios.post('/products', { _method: 'DELETE', ids }).then(response => {
      commit(PRODUCTS_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(PRODUCTS_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchMany ({ commit }, { query = '', page = 1 }) {
    commit(PRODUCTS_FETCH_MANY_REQUEST)
    return window.axios.get('/products', { params: { query, page } }).then(response => {
      commit(PRODUCTS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(PRODUCTS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetch ({ commit }, id) {
    commit(PRODUCTS_FETCH_REQUEST)
    return window.axios.get(`/products/${id}`).then(response => {
      commit(PRODUCTS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(PRODUCTS_FETCH_FAILURE, error)
      throw error
    })
  },
  save ({ commit }, product) {
    const path = product.id ? `/products/${product.id}` : '/products'
    const method = product.id ? 'put' : 'post'

    commit(PRODUCTS_SAVE_REQUEST)
    return window.axios[method](path, product).then(response => {
      commit(PRODUCTS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(PRODUCTS_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(PRODUCTS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  empty({ commit }) {
    commit(EMPTY_PRODUCTS);
  },
  clearErrors({ commit }) {
    commit(CLEAR_ERRORS)
  }
}
