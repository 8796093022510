<template>
  <router-view />
</template>

<script>
import customerModule from '../../store/customers'
import productModule from '../../store/products'
import groupModule from '../../store/groups'
import scheduledCallModule from '../../store/scheduled-calls'
import callModule from '../../store/calls'
import userModule from '../../store/users'
import postcodeModule from '../../store/postcodes'
import templateModule from '../../store/templates'

export default {
  beforeCreate () {
    this.$store.registerModule('customers', customerModule)
    this.$store.registerModule('products', productModule)
    this.$store.registerModule('groups', groupModule)
    this.$store.registerModule('scheduled-calls', scheduledCallModule)
    this.$store.registerModule('calls', callModule)
    this.$store.registerModule('users', userModule)
    this.$store.registerModule('postcodes', postcodeModule)
    this.$store.registerModule('templates', templateModule)
  },
  destroyed () {
    this.$store.unregisterModule('customers')
    this.$store.unregisterModule('products')
    this.$store.unregisterModule('groups')
    this.$store.unregisterModule('scheduled-calls')
    this.$store.unregisterModule('calls')
    this.$store.unregisterModule('users')
    this.$store.unregisterModule('postcodes')
    this.$store.unregisterModule('templates')
  }
}
</script>
