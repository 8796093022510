import {
  CALLS_FETCH_MANY_REQUEST,
  CALLS_FETCH_MANY_SUCCESS,
  CALLS_FETCH_MANY_FAILURE,
  CALLS_FETCH_REQUEST,
  CALLS_FETCH_SUCCESS,
  CALLS_FETCH_FAILURE
} from './types'

export default {
  fetchMany ({ commit }, { query = '', page = 1 }) {
    commit(CALLS_FETCH_MANY_REQUEST)
    return window.axios.get('/calls', { params: { query, page } }).then(response => {
      commit(CALLS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(CALLS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchManyMissed ({ commit }, { query = '', page = 1 }) {
    commit(CALLS_FETCH_MANY_REQUEST)
    return window.axios.get('/calls/missed', { params: { query, page } }).then(response => {
      commit(CALLS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(CALLS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetch ({ commit }, id) {
    commit(CALLS_FETCH_REQUEST)
    return window.axios.get(`/calls/${id}`).then(response => {
      commit(CALLS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(CALLS_FETCH_FAILURE, error)
      throw error
    })
  },
  sendCallAttempted({ commit, dispatch }, callId) {
    return window.axios.get(`/calls/unreachable/${callId}`).then(response => {
      dispatch('fetchManyMissed', {})
    })
  },
  doDismissCall({ commit, dispatch }, callId) {
    return window.axios.get(`/calls/dismiss/${callId}`).then(response => {
      dispatch('fetchManyMissed', {})
    })
  }
}
