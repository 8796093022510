import pagination from '../../utils/pagination'
import {
  CALLS_FETCH_MANY_REQUEST, CALLS_FETCH_MANY_SUCCESS, CALLS_FETCH_MANY_FAILURE,
  CALLS_FETCH_REQUEST, CALLS_FETCH_SUCCESS, CALLS_FETCH_FAILURE,
} from './types'

export default {
  [CALLS_FETCH_MANY_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [CALLS_FETCH_MANY_SUCCESS] (state, { data }) {
    state.data = data.data
    state.page = pagination(data)
    state.loading = false
  },
  [CALLS_FETCH_MANY_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [CALLS_FETCH_REQUEST] (state) {
    state.single = {}
    state.loading = true
  },
  [CALLS_FETCH_SUCCESS] (state, { data }) {
    state.single = data
    state.loading = false
  },
  [CALLS_FETCH_FAILURE] (state) {
    state.loading = false
  },
}
